html,
body {
  margin: 0;
  padding: 4px;
}
body {
  background: midnightblue;
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-position: center;
  background-size:cover;
  background-image: linear-gradient(147deg, #923cb5 0%, #000000 74%); 
  color: white;
  font-size: 150%;
  margin: 0;
  padding: 10px;

  font-family: "Quicksand", sans-serif;
  font-size: 1.5rem;
  font-weight: 300;

  min-height: calc(100vh);
}
a {
  color: white;
}

#app {
  position: relative;
  max-width: 600px;
  min-height: 400px;
  border: 1px solid black;
  border-radius: 10px;
  margin: auto;
}

.glass-card {
  backdrop-filter: blur(1px);
  color: white;
  height: auto;
  min-width: 100px;
  padding: 2rem;
  background: rgba(255, 255, 255, 0.1);
  border-radius: 10px;
  border: 1px solid rgba(255, 255, 255, 0.18);
  margin-bottom: 22px;
  overflow: hidden;
  text-overflow: ellipsis;
}

.coin{
  zoom: 50%;
  transform: scale(.05);
}

.blink {
  animation: blink-animation 1s steps(5, start) infinite;
  -webkit-animation: blink-animation 1s steps(5, start) infinite;
}
@keyframes blink-animation {
  to {
    visibility: hidden;
  }
}
@-webkit-keyframes blink-animation {
  to {
    visibility: hidden;
  }
}

 
